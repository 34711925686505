import React from 'react';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { WidgetsMode } from '../../../common/sgwt-widgets-utils';

interface SGLabelProps extends ITranslatorProps {
  corporateName?: string;
  widgetsMode: WidgetsMode | null;
}

export const SGLabel = ({ corporateName, translator, widgetsMode }: SGLabelProps) => {
  const year = new Date().getFullYear().toString();
  // In mode 'sgef', the Corporate name can be overriden by the `corporate-name` attribute.
  const overriden = widgetsMode === 'sgef' && corporateName ? corporateName : undefined;
  const corporate = (overriden ?? translator.translate('corporateMention')).replace('{year}', year);
  const corporateShort = (overriden ?? 'SG').replace('{year}', year);

  return (
    <span className="h6 mb-0 font-weight-normal fw-normal sgwt-mini-footer-sg-label">
      <span className="d-none d-md-inline-block">&copy; {corporate}</span>
      <span className="d-md-none">&copy; {corporateShort}</span>
    </span>
  );
};
