import React from 'react';
import { registerMiniFooterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { emptyObject, WidgetsMode } from '../../../common/sgwt-widgets-utils';
import { ISgwtMiniFooterDropUpItem, ISgwtMiniFooterLegalNotices } from '../sgwt-mini-footer.types';
import { COOKIES_CONSENT_MGMT, SGM_LEGAL_NOTICES, SGM_LEGAL_NOTICES_FR, SgmLegalNotice } from '../shared/legal-notices';
import { getDidomi } from './cookies-consent';

interface DropupItemProps {
  label: string;
  onClick?: () => void;
  onNextStep?: (event: React.MouseEvent<HTMLElement>) => void;
  openInSamePage: boolean;
  value?: string;
}

const DropupItem = ({ label, onClick, onNextStep, openInSamePage, value }: DropupItemProps) => {
  const hasNextStep = typeof onNextStep !== 'undefined';
  const href = hasNextStep ? '#' : value;
  return (
    <a
      className="dropdown-item border-0"
      href={href}
      onClick={hasNextStep ? onNextStep : onClick}
      rel="noreferrer"
      target={hasNextStep || openInSamePage ? '_self' : '_blank'}
    >
      {label}
    </a>
  );
};

interface LegalNoticesProps extends ITranslatorProps {
  cookiesConsent: boolean;
  legalNotices?: ISgwtMiniFooterLegalNotices;
  userConnected: boolean;
  widgetsMode: WidgetsMode | null;
}

interface ILegalNoticesState {
  isOpen: boolean;
}

export class LegalNotices extends React.Component<LegalNoticesProps, ILegalNoticesState> {
  private handler?: HTMLLIElement;

  constructor(props: LegalNoticesProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  private openDidomiPreferences = () => {
    this.setState({ isOpen: false });
    const didomi = getDidomi();
    if (didomi !== null) {
      didomi.preferences.show();
    }
  };

  public render() {
    const { legalNotices } = this.props;
    const language = this.props.translator.getCurrentLanguage();

    // For SG Markets applications, all links are displayed, except the "Important Notice"
    // which is visible only by connected users.
    const links: SgmLegalNotice[] = language === 'fr' ? SGM_LEGAL_NOTICES_FR : SGM_LEGAL_NOTICES;
    let sgMarketsLinks: SgmLegalNotice[] = [];
    if (this.props.widgetsMode === 'sg-markets') {
      sgMarketsLinks = links.filter((notice) => notice.type !== 'important-notice' || this.props.userConnected);
    } else if (this.props.cookiesConsent) {
      sgMarketsLinks = [COOKIES_CONSENT_MGMT];
    }

    let customNotices: ISgwtMiniFooterDropUpItem[] = [];
    if (!emptyObject(legalNotices)) {
      customNotices = legalNotices![legalNotices!.hasOwnProperty(language) ? language : 'en']; // eslint-disable-line
    }

    // If we don't have any link to display, then we hide the component...
    if (customNotices.length === 0 && sgMarketsLinks.length === 0) {
      return null;
    }

    const hasDelimiter = sgMarketsLinks.length > 0 && !emptyObject(legalNotices);

    const dropupContent = (
      <div
        aria-labelledby="Legal Notices"
        data-bs-popper
        className={`dropdown-menu dropdown-menu-right dropdown-menu-end ${this.state.isOpen && 'visible show'}`}
      >
        {/* Custom links */}
        {customNotices.map(({ label, value, openInSamePage }) => (
          <DropupItem
            key={`notice-${label}`}
            label={label}
            openInSamePage={openInSamePage || false}
            onClick={() => this.handleClick(label, true)}
            value={value}
          />
        ))}
        {/* Delimiter */}
        {hasDelimiter && <div className="dropdown-divider" />}
        {/* SG Markets links */}
        {sgMarketsLinks.map(({ label, type, value }) => {
          if (type === 'cookies-consent') {
            return getDidomi() !== null ? (
              <button
                key="notice-cookies-consent"
                className="dropdown-item font-weight-medium"
                onClick={this.openDidomiPreferences}
              >
                {this.props.translator.translate(label)}
              </button>
            ) : null;
          }
          return (
            <DropupItem
              key={`notice-${label}`}
              label={this.props.translator.translate(label)}
              onClick={() => this.handleClick(label, false)}
              openInSamePage={false}
              value={value as string}
            />
          );
        })}
        <div className="dropdown-divider d-sm-none" />
        <button className="dropdown-item d-sm-none font-weight-medium" onClick={this.handleClose}>
          {this.props.translator.translate('close')}
        </button>
      </div>
    );

    return (
      <li
        className="ml-3 ms-3 dropup"
        ref={(handler: HTMLLIElement) => {
          this.handler = handler;
        }}
      >
        <button
          aria-expanded={this.state.isOpen}
          aria-haspopup="true"
          className="btn-flat-secondary sgbs-footer-item dropdown-toggle h6 font-weight-normal fw-normal"
          onClick={this.handleDropupToggle}
        >
          <span className="d-none d-sm-inline">{this.props.translator.translate('legalNotices')}</span>
          <span className="d-sm-none">
            {this.props.translator.translate('legalNoticesShort') || this.props.translator.translate('legalNotices')}
          </span>
        </button>
        {dropupContent}
      </li>
    );
  }

  private handleClick = (label: string, customLink: boolean) => {
    if (!customLink) {
      const link = label.toLowerCase().replace(/ /g, '');
      registerMiniFooterEvent(`legal-notices.click-${customLink ? 'custom-link' : 'link'}`, { link });
    }
    this.handleClose();
  };

  private handleClose = (event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      event.preventDefault();
    }
    document.removeEventListener('click', this.handleOutsideClick, false);
    document.removeEventListener('touchend', this.handleOutsideClick, false);
    document.removeEventListener('keydown', this.handleKeydown, false);
    this.setState({ isOpen: false });
    registerMiniFooterEvent('legal-notices.dropdown-close');
  };

  private handleDropupToggle = (event: React.MouseEvent<HTMLElement>) => {
    if (event) {
      event.preventDefault();
    }
    const { isOpen } = this.state;
    if (isOpen) {
      this.handleClose();
    } else {
      document.addEventListener('click', this.handleOutsideClick, false);
      document.addEventListener('touchend', this.handleOutsideClick, false);
      document.addEventListener('keydown', this.handleKeydown, false);
      this.setState({ isOpen: true });
      registerMiniFooterEvent('legal-notices.dropdown-open');
    }
  };

  private handleKeydown = (event: KeyboardEvent) => {
    const { isOpen } = this.state;
    if (isOpen && (event.key === 'Escape' || event.which === 27)) {
      this.handleClose();
    }
  };

  private handleOutsideClick = (event: MouseEvent | TouchEvent) => {
    if (this.handler && !this.handler.contains(event.target as Node)) {
      this.handleClose();
    }
  };
}
