import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import {
  BUS_GLOBAL_LANGUAGE,
  BUS_USER_CONNECTION,
  ISgwtUserConnectionStatus,
  SubscriptionHandle,
} from '../../../common/auth/bus-topics';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../../common/configuration';
import { registerMiniFooterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { modeSGMarketsEnabled, whichMode } from '../../../common/sgwt-widgets-utils';
import { ISgwtMiniFooterContactUsList, ISgwtMiniFooterLegalNotices } from '../sgwt-mini-footer.types';
import { getAccessibilityStatementPageUrl } from '../shared/mini-footer.endpoint';
import { CompactFooter } from './CompactFooter';
import { IUserConsent } from './cookies-consent';
import { MediumFooter } from './MediumFooter';

interface MiniFooterProps extends ITranslatorProps {
  about: string;
  accessibilityCompliance?: string;
  accessibilityLink?: string;
  accessibilityUid?: string;
  contactUs?: ISgwtMiniFooterContactUsList;
  contactUsByHelpCenter?: string;
  container?: string;
  cookiesConsent: boolean;
  cookieConsentLanguage?: string;
  corporateName?: string;
  design?: string;
  emitUserConsentEvent: (eventName: 'user-consent-changed' | 'current-user-consent', consents: IUserConsent[]) => void;
  legalNotices?: ISgwtMiniFooterLegalNotices;
  logoLink?: string;
  logoLinkTitle?: string;
  mode?: string;
  noBorder?: boolean;
  onAboutClicked: () => void;
  onAccessibilityClicked: () => void;
  type?: string;
}

interface IMiniFooterState {
  language: string;
  userConnected: boolean;
}

export default class SgwtMiniFooter extends React.Component<MiniFooterProps, IMiniFooterState> {
  static readonly contextType = WidgetConfigurationContext;
  private languageSubscription?: SubscriptionHandle;
  private userConnectionSubscription?: SubscriptionHandle;
  private widgetConfiguration: WidgetConfiguration;

  constructor(props: MiniFooterProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
    this.state = {
      userConnected: false,
      language: this.props.translator.getCurrentLanguage(),
    };
  }

  public componentDidMount() {
    // Listen to language modification
    this.languageSubscription = this.widgetConfiguration.bus.subscribe<string>(BUS_GLOBAL_LANGUAGE, (language) => {
      this.props.translator.changeCurrentLanguage(language || 'en');
      this.setState({ language: language || 'en' });
    });

    // Listen to user connection status
    this.userConnectionSubscription = this.widgetConfiguration.bus.subscribe<ISgwtUserConnectionStatus>(
      BUS_USER_CONNECTION,
      (status) => {
        this.setState({
          userConnected: status !== undefined && status.connected,
        });
      },
    );
  }

  public componentWillUnmount() {
    // Unsubscribe to widget bus updates
    if (this.languageSubscription) {
      this.widgetConfiguration.bus.unsubscribe(this.languageSubscription);
    }
    if (this.userConnectionSubscription) {
      this.widgetConfiguration.bus.unsubscribe(this.userConnectionSubscription);
    }
  }

  clickOnAbout = () => {
    this.props.onAboutClicked();
    registerMiniFooterEvent('links.click-about');
  };

  clickOnAccessibility = () => {
    registerMiniFooterEvent('links.click-accessibility');
  };

  render() {
    const widgetsMode = whichMode(this.props.mode);
    const cookiesConsent = widgetsMode === 'sg-markets' || widgetsMode === 'b2b2c' || !!this.props.cookiesConsent;
    let accessibilityCompliance = (this.props.accessibilityCompliance || '').toLowerCase();
    let accessibilityLink = this.props.accessibilityLink;
    const lang = this.state.language === 'fr' ? 'fr' : 'en';

    if (!accessibilityLink && !!this.props.accessibilityUid) {
      accessibilityLink = getAccessibilityStatementPageUrl(lang, this.props.accessibilityUid);
    } else if (modeSGMarketsEnabled(this.props.mode)) {
      // Default e-accessibility compliance level - restricted to SG Markets for the moment.
      if (!accessibilityCompliance) {
        accessibilityCompliance = 'none';
      }
      if (accessibilityCompliance === 'none' && !accessibilityLink) {
        accessibilityLink = getAccessibilityStatementPageUrl(lang);
      }
    }

    if (this.props.type === 'medium') {
      return (
        <MediumFooter
          {...this.props}
          accessibilityCompliance={accessibilityCompliance}
          accessibilityLink={accessibilityLink}
          accessibilityUid={this.props.accessibilityUid}
          cookiesConsent={cookiesConsent}
          language={this.state.language}
          userConnected={this.state.userConnected}
          onAboutClicked={this.clickOnAbout}
          onAccessibilityClicked={this.clickOnAccessibility}
          widgetsMode={widgetsMode}
        />
      );
    }
    return (
      <CompactFooter
        {...this.props}
        accessibilityCompliance={accessibilityCompliance}
        accessibilityLink={accessibilityLink}
        accessibilityUid={this.props.accessibilityUid}
        cookiesConsent={cookiesConsent}
        language={this.state.language}
        microFooter={this.props.type === 'micro'}
        userConnected={this.state.userConnected}
        onAboutClicked={this.clickOnAbout}
        onAccessibilityClicked={this.clickOnAccessibility}
        widgetsMode={widgetsMode}
      />
    );
  }
}
